import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import All from './pages/All';
import Edit from './pages/Edit';
import Login from './pages/Login';

import ReactangleDuma from './pages/reactangleduma';
import HeaderDuma from './pages/headerduma';

import ReactangleAtleti from './pages/reactangleatleti';
import HeaderAtleti from './pages/headeratleti';

import ReactangleFw from './pages/reactanglefw';
import HeaderFw from './pages/headerfw';

import ReactanglePsg from './pages/reactanglepsg';
import HeaderPsg from './pages/headerpsg';


class App extends Component {

  render() {
    return (
      <Router>
        <div className="App">

          <Switch>
          
          <Route exact path="/"> <All controller={"content"} /> </Route>
          <Route exact path="/login"> <Login info={"login"} /> </Route>
          

            <Route exact path="/content/all"> <All controller={"content"} /> </Route>
            <Route exact path="/content/edit/:id" render = {props => <Edit {...props} controller={"content"} type={"edit"} /> } />
            <Route exact path="/content/add" render = {props => <Edit {...props} controller={"content"} type={"add"} /> } />
          
            <Route exact path="/cat/all"> <All controller={"cat"} /> </Route>
            <Route exact path="/cat/edit/:id" render = {props => <Edit {...props} controller={"cat"} type={"edit"} /> } />
            <Route exact path="/cat/add" render = {props => <Edit {...props} controller={"cat"} type={"add"} /> } />
         
            <Route exact path="/bookcontent/all"> <All controller={"bookcontent"} type={"add"} /> </Route>
            <Route exact path="/bookcontent/edit/:id" render = {props => <Edit {...props} controller={"bookcontent"} type={"edit"} /> } />
            <Route exact path="/bookcontent/add" render = {props => <Edit {...props} controller={"bookcontent"} type={"add"} /> } />
         
            <Route exact path="/promocode/all"> <All controller={"promocode"} type={"add"} /> </Route>
            <Route exact path="/promocode/edit/:id" render = {props => <Edit {...props} controller={"promocode"} type={"edit"} /> } />
            <Route exact path="/promocode/add" render = {props => <Edit {...props} controller={"promocode"} type={"add"} /> } />
         
            <Route exact path="/event/all"> <All controller={"event"} type={"add"} /> </Route>
            <Route exact path="/event/edit/:id" render = {props => <Edit {...props} controller={"event"} type={"edit"} /> } />
            <Route exact path="/event/add" render = {props => <Edit {...props} controller={"event"} type={"add"} /> } />
         
			      <Route exact path="/reactangleduma"> <ReactangleDuma /> </Route>
            <Route exact path="/headerduma"> <HeaderDuma /> </Route>

            <Route exact path="/reactangleatleti"> <ReactangleAtleti /> </Route>
            <Route exact path="/headeratleti"> <HeaderAtleti /> </Route>

            <Route exact path="/reactanglefw"> <ReactangleFw /> </Route>
            <Route exact path="/headerfw"> <HeaderFw /> </Route>

            <Route exact path="/reactanglepsg"> <ReactanglePsg /> </Route>
            <Route exact path="/headerpsg"> <HeaderPsg /> </Route>

          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;