
import React from "react";
import axios from 'axios';

class Rectangle_psg extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      geo: "no"
    };


  }




  componentDidMount() {
    this.geo();
  }




  geo() {
    axios.get('https://api.typuje.pl/api/geo',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(results => {
        console.log(results.data.trim());
        this.setState({
          geo: results.data.trim()
        });
      })
  }

  render() {
    return (
      <div style={{backgroundColor : "white"}}>

        {this.state.geo == "no" ?   ""
           :
          this.state.geo != "pl" ?
            <a href="https://psgonline.pl/go/20bet" target="_blank" rel="nofollow" className="btn"><img src="https://bets.pl/img/300/20bet.jpg" alt="" style={{ width: "100%", maxWidth: "300px" }} /></a>
            :  <a href="https://psgonline.pl/go/etoto" target="_blank" rel="nofollow" className="btn"><img src="https://psgonline.pl/wp-content/uploads/2022/08/etoto2.jpg" alt="" style={{ width: "100%", maxWidth: "300px" }} /></a>
          }
      </div>
    );
  }
}

export default Rectangle_psg;