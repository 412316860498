import React from 'react';
import { Redirect } from 'react-router-dom';
import Login from '../pages/Login';

   
   function Logged() {
     return (
          <li className="nav-item dropdown d-none d-xl-inline-block user-dropdown">
          <strong> Zalogowany jako :</strong> {"Admin   "}    
          <button onClick={Logout}> Wyloguj</button>
          </li>

     );
   }
   
   
   function Sprawdz() {
     const isLoggedIn = localStorage.getItem('token');
     if (isLoggedIn==null) {
       return    <Redirect to="/login" />;
     }
     return <Logged />;
   }

   function Logout() {
     localStorage.removeItem("token");
     window.location = "/login";
   }



export default function MenuBar() {
     return (
          <div className="container-scroller">
               <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                    <div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
                         <a className="navbar-brand brand-logo" href="index.html">
                              <img src="/images/logo.svg" alt="logo" /> </a>
                         <a className="navbar-brand brand-logo-mini" href="index.html">
                              <img src="/images/logo-mini.svg" alt="logo" /> </a>
                    </div>
                    <div className="navbar-menu-wrapper d-flex align-items-center">
                         <ul className="navbar-nav">
                              <li className="nav-item font-weight-semibold d-none d-lg-block">Help : contact@efterprise.com</li>
                                                     </ul>
                         <form className="ml-auto search-form d-none d-md-block" action="#">
                              <div className="form-group">
                                   <input type="search" className="form-control" placeholder="Search Here" />
                              </div>
                         </form>
                         <ul className="navbar-nav ml-auto">
                              <li className="nav-item dropdown">
                                   <a className="nav-link count-indicator" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                                        <i className="mdi mdi-bell-outline"></i>
                                        <span className="count">0</span>
                                   </a>
                                   <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="messageDropdown">
                                   </div>
                              </li>
                              <li className="nav-item dropdown">
                                   <a className="nav-link count-indicator" id="notificationDropdown" href="#" data-toggle="dropdown">
                                        <i className="mdi mdi-email-outline"></i>
                                        <span className="count bg-success">3</span>
                                   </a>
                              </li>
                            <Sprawdz />  
                         
                             
                         </ul>
                         <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                              <span className="mdi mdi-menu"></span>
                         </button>
                    </div>
               </nav>
          </div>
     );
}