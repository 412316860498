import React from 'react';
import StartArea from './startarea';
import MenuBar from './menubar';
import "../assets/vendors/iconfonts/mdi/css/materialdesignicons.min.css";
import "../assets/vendors/iconfonts/ionicons/dist/css/ionicons.css";
import "../assets/vendors/iconfonts/flag-icon-css/css/flag-icon.min.css";
import "../assets/vendors/css/vendor.bundle.base.css";
import "../assets/vendors/css/vendor.bundle.addons.css";
import "../assets/css/shared/style.css";
import "../assets/css/demo_1/style.css";

export default class Layout extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div>

                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <title>Star Admin Premium Bootstrap Admin Dashboard Template</title>
                <script src="/js/jquery-3.3.1.min.js" type="text/javascript"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.0/umd/popper.min.js" integrity="sha384-cs/chFZiN24E4KMATLdqdvsezGxaGsi4hLGOzlXwp5UZB1LY//20VyM2taTB4QvJ" crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/js/bootstrap.min.js" integrity="sha384-uefMccjFJAIv6A+rW+L4AHf99KvxDjWSu1z9VI8SKNVmz4sk7buKt/6v9KI65qnm" crossorigin="anonymous"></script>


                <MenuBar />

                <div className="container-fluid page-body-wrapper">

                    <StartArea />
                    {this.props.children}
                </div>

            </div>
        );
    }
}

